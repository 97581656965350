import { useRouter } from 'next/dist/client/router';
import { useEffect, useState } from 'react';
import { requestParams } from '@/lib/requestParams';

export const useFlashMessage = () => {
  const router = useRouter();
  const [flashMessage, setFlashMessage] = useState('');

  useEffect(() => {
    const { flash } = requestParams(router.asPath);
    if (flash && flash.length) {
      // バックエンドからはURI.encodeしたものが渡される
      setFlashMessage(decodeURI(flash));
    }
  }, [router.query]);

  return {
    flashMessage,
    setFlashMessage
  };
};
