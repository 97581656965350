import { AppProps } from 'next/app';
import Head from 'next/head';
import styles from '@/styles/global.scss?type=global';
import { AuthContext, useAuth } from '@/hooks/auth';
import { SchoolContext, useSchool } from '@/hooks/school';
import { ErrorContext, useError } from '@/hooks/error';
import { ErrorConductor } from '@/components/organizers/error/ErrorConductor';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ApiContext, useApi } from '@/hooks/api';
import { useEffect } from 'react';
import { GA_TRACKING_ID, pageview } from '@/lib/gtag';
import { Router } from 'next/router';

Router.events.on('routeChangeComplete', (url) => pageview(url));

const App: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
  const error = useError();
  const api = useApi(error);
  const auth = useAuth(api);
  const school = useSchool(api);

  useEffect(() => {
    const subDomain = window.location.host.split('.')[0];
    const isServiceDomain = RegExp(process.env.SERVICE_DOMAIN as string).test(subDomain);
    if ('www' === subDomain || isServiceDomain) {
      window.location.href = process.env.LP_BASE_URL as string;
    }
  }, []);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#47A0AB'
      }
    }
  });

  return (
    <>
      <Head>
        <title>{school.school?.name}</title>
        <meta name="description" content={school.school?.description || ''} />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/modern-css-reset/dist/reset.min.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        {GA_TRACKING_ID && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GA_TRACKING_ID}', {
                    page_path: window.location.pathname
                  });`
              }}
            />
          </>
        )}
      </Head>
      <ThemeProvider theme={theme}>
        <ErrorContext.Provider value={error}>
          <ApiContext.Provider value={api}>
            <AuthContext.Provider value={auth}>
              <SchoolContext.Provider value={school}>
                <ErrorConductor>
                  <Component {...pageProps} />
                </ErrorConductor>
              </SchoolContext.Provider>
            </AuthContext.Provider>
          </ApiContext.Provider>
        </ErrorContext.Provider>
      </ThemeProvider>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default App;
