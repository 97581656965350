import { useContext, createContext, useEffect } from 'react';
import { UseErrorContextState } from '@/hooks/error/errorInterface';
import $api from '@/api/$api';
import aspida from '@aspida/axios';
import { axios } from '@/lib/axios';
import { AxiosError } from 'axios';
import { authCookies } from '@/hooks/auth';
import { EventEmitter } from 'events';

/** axiosのエラーを購読するためのイベントバス */
const errorEventBus = new EventEmitter();
export enum ErrorEventType {
  'error' = 'error',
  'unauthorized' = 'unauthorized'
}
const isUnauthorizedError = (error: AxiosError) => error?.response?.status === 401;
const isMaintenance = (error: AxiosError) => {
  try {
    return error?.response?.status === 503 && error.response?.data?.maintenance;
  } catch {
    return false;
  }
};

const isSchoolNotFoundError = (error: AxiosError) => {
  return error?.response?.status === 404 && error?.response?.data?.data?.reason === 'school_not_found';
};

type AxiosErrorWithFag = {
  /** このエラーが意図した動作でありエラートラッカーで追跡する必要が無いことを表すフラグ */
  handled?: boolean;
} & AxiosError;

// request inetrceptor
axios.interceptors.request.use((config) => {
  // 認証情報をリクエストヘッダーに追加
  const authParams = authCookies.get();
  config.headers.uid = authParams?.uid;
  config.headers['access-token'] = authParams?.accessToken;
  return config;
});

// response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isUnauthorizedError(error)) {
      errorEventBus.emit(ErrorEventType.unauthorized, error);
    }
    errorEventBus.emit(ErrorEventType.error, error);
    throw error;
  }
);

export const useApi = (errorCtx: UseErrorContextState) => {
  const LOCATION_PROTOCOL = typeof window !== 'undefined' ? location.protocol : '';
  const LOCATION_HOSTNAME = typeof window !== 'undefined' ? location.hostname : '';
  const API_PORT = process.env.API_PORT ? `:${process.env.API_PORT}` : '';

  const API_BASE_URL = LOCATION_PROTOCOL + '//' + LOCATION_HOSTNAME + API_PORT;

  useEffect(() => {
    const handleAxiosError = (error: AxiosErrorWithFag) => {
      if (isMaintenance(error)) {
        errorCtx.setMaintenance(true);
        error.handled = true;
        return;
      }

      // エラーレスポンスに複数パターンあるのでその対応
      const errorsMsg = (error?.response?.data?.errors || []).join('\n');
      const errorMsg = error?.response?.data?.error;

      if (isSchoolNotFoundError(error)) {
        errorCtx.pushError(errorsMsg || errorMsg);
        error.handled = true;
        errorCtx.setNotFoundPage(true);
        return;
      }

      if (errorsMsg || errorMsg) {
        errorCtx.pushError(errorsMsg || errorMsg);
        error.handled = true;
      } else {
        errorCtx.pushError(`予期せぬエラーが発生しました。(${error?.response?.status})`);
      }
    };

    errorEventBus.on(ErrorEventType.error, handleAxiosError);
    return () => {
      errorEventBus.off(ErrorEventType.error, handleAxiosError);
    };
  }, []);

  const { api } = $api(
    aspida(axios, {
      baseURL: API_BASE_URL
    })
  );

  type IssueUrlParameter = Parameters<typeof api.schools.upload.issue_url.$post>[0]['body'];
  type ExistsParameter = Parameters<typeof api.schools.upload.exists.$post>[0]['body'];
  type CallbackFn = (params: {
    publishUrl: ReturnTypeDigPromise<typeof api.schools.upload.exists.$post>['data']['url'];
    contentKey: string;
  }) => void;

  return {
    api,
    utils: {
      uploadContent: (file: Blob, uploadType: IssueUrlParameter['upload_type']) => {
        /** ファイルアップロードURLを発行する */
        const issueUploadUrl = async (body: IssueUrlParameter) => {
          return await api.schools.upload.issue_url.$post({ body });
        };

        /** S3にファイルアップロードする */
        const uploadFileForS3 = async (url: string) => {
          return await axios.put(url, file, {
            headers: {
              'Content-Type': file.type
            }
          });
        };

        /** アップロード済みファイルの存在確認 */
        const existsUploadFile = async (body: ExistsParameter, callback: CallbackFn) => {
          const exists = async () => {
            const res = await api.schools.upload.exists.$post({ body: body });
            const publishUrl = res.data.url;

            callback({ publishUrl, contentKey: body.check_key_path });

            return !!publishUrl;
          };

          let result = await exists();
          let intervalId = null;

          if (!result) {
            intervalId = setInterval(async () => {
              result = await exists();
            }, 3000);
          }

          return intervalId;
        };

        return {
          execute: async (callback: CallbackFn) => {
            const issueReq: IssueUrlParameter = {
              upload_type: uploadType,
              content_type: file.type
            };
            const issueRes = await issueUploadUrl(issueReq);
            const contentKey = issueRes.data.content.key;
            const uploadUrl = issueRes.data.content.url;

            await uploadFileForS3(uploadUrl);

            const existsReq: ExistsParameter = {
              check_key_path: contentKey
            };
            return await existsUploadFile(existsReq, callback);
          }
        };
      }
    },
    /** APIのエラーを購読するためのイベントバス */
    errorEventBus
  };
};

export type UseApiContextState = ReturnType<typeof useApi>;
export const ApiContext = createContext({} as UseApiContextState);
export const useApiContext = () => useContext(ApiContext);
