import styles from './Maintenance.scss';

export const Maintenance: React.FC = () => {
  return (
    <main className="maintenance">
      <div>
        <h1>メンテナンス中</h1>
        <div className="message">
          <p>ただいまメンテナンス中です。メンテナンス終了までしばらくお待ちください。</p>
        </div>
      </div>
      <style jsx>{styles}</style>
    </main>
  );
};
