/* eslint-disable */
// prettier-ignore
import { AspidaClient, BasicHeaders, dataToURLString } from 'aspida'
// prettier-ignore
import { Methods as Methods0 } from './api/schools/courses'
// prettier-ignore
import { Methods as Methods1 } from './api/schools/courses/_course_key@string'
// prettier-ignore
import { Methods as Methods2 } from './api/schools/courses/_course_key@string/_lesson_key@string'
// prettier-ignore
import { Methods as Methods3 } from './api/schools/courses/_course_key@string/lessons_order'
// prettier-ignore
import { Methods as Methods4 } from './api/schools/invite'
// prettier-ignore
import { Methods as Methods5 } from './api/schools/members'
// prettier-ignore
import { Methods as Methods6 } from './api/schools/members/_school_member_id@number'
// prettier-ignore
import { Methods as Methods7 } from './api/schools/sales'
// prettier-ignore
import { Methods as Methods8 } from './api/schools/sales/courses'
// prettier-ignore
import { Methods as Methods9 } from './api/schools/school'
// prettier-ignore
import { Methods as Methods10 } from './api/schools/upload/exists'
// prettier-ignore
import { Methods as Methods11 } from './api/schools/upload/issue_url'
// prettier-ignore
import { Methods as Methods12 } from './api/users/auth'
// prettier-ignore
import { Methods as Methods13 } from './api/users/auth/password'
// prettier-ignore
import { Methods as Methods14 } from './api/users/auth/sign_in'
// prettier-ignore
import { Methods as Methods15 } from './api/users/auth/sign_up'
// prettier-ignore
import { Methods as Methods16 } from './api/users/courses'
// prettier-ignore
import { Methods as Methods17 } from './api/users/courses/_course_key@string'
// prettier-ignore
import { Methods as Methods18 } from './api/users/courses/_course_key@string/_lesson_key@string'
// prettier-ignore
import { Methods as Methods19 } from './api/users/courses/_course_key@string/subscribe'
// prettier-ignore
import { Methods as Methods20 } from './api/users/courses/_course_key@string/verify'
// prettier-ignore
import { Methods as Methods21 } from './api/users/payment/histories'
// prettier-ignore
import { Methods as Methods22 } from './api/users/payment/methods'
// prettier-ignore
import { Methods as Methods23 } from './api/users/school'
// prettier-ignore
import { Methods as Methods24 } from './api/users/settings/apply_change_email'

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://teachyou-school.local-teachyou.com:3001' : baseURL).replace(/\/$/, '')
  const PATH0 = '/api/schools/courses'
  const PATH1 = '/lessons_order'
  const PATH2 = '/api/schools/invite'
  const PATH3 = '/api/schools/members'
  const PATH4 = '/api/schools/sales'
  const PATH5 = '/api/schools/sales/courses'
  const PATH6 = '/api/schools/school'
  const PATH7 = '/api/schools/upload/exists'
  const PATH8 = '/api/schools/upload/issue_url'
  const PATH9 = '/api/users/auth'
  const PATH10 = '/api/users/auth/password'
  const PATH11 = '/api/users/auth/sign_in'
  const PATH12 = '/api/users/auth/sign_up'
  const PATH13 = '/api/users/courses'
  const PATH14 = '/subscribe'
  const PATH15 = '/verify'
  const PATH16 = '/api/users/payment/histories'
  const PATH17 = '/api/users/payment/methods'
  const PATH18 = '/api/users/school'
  const PATH19 = '/api/users/settings/apply_change_email'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'

  return {
    api: {
      schools: {
        courses: {
          _course_key: (val3: string) => {
            const prefix3 = `${PATH0}/${val3}`

            return {
              _lesson_key: (val4: string) => {
                const prefix4 = `${prefix3}/${val4}`

                return {
                  /**
                   * レッスン情報削除するAPI
                   * @returns 正常
                   */
                  delete: (option?: { config?: T }) =>
                    fetch<Methods2['delete']['resBody'], BasicHeaders, Methods2['delete']['status']>(prefix, prefix4, DELETE, option).json(),
                  /**
                   * レッスン情報削除するAPI
                   * @returns 正常
                   */
                  $delete: (option?: { config?: T }) =>
                    fetch<Methods2['delete']['resBody'], BasicHeaders, Methods2['delete']['status']>(prefix, prefix4, DELETE, option).json().then(r => r.body),
                  /**
                   * スクール側のレッスン情報を取得する
                   * @returns 正常
                   */
                  get: (option: { query: Methods2['get']['query'], config?: T }) =>
                    fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * スクール側のレッスン情報を取得する
                   * @returns 正常
                   */
                  $get: (option: { query: Methods2['get']['query'], config?: T }) =>
                    fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * レッスン情報を更新するAPI
                   * @returns 正常
                   */
                  put: (option: { body: Methods2['put']['reqBody'], config?: T }) =>
                    fetch<Methods2['put']['resBody'], BasicHeaders, Methods2['put']['status']>(prefix, prefix4, PUT, option).json(),
                  /**
                   * レッスン情報を更新するAPI
                   * @returns 正常
                   */
                  $put: (option: { body: Methods2['put']['reqBody'], config?: T }) =>
                    fetch<Methods2['put']['resBody'], BasicHeaders, Methods2['put']['status']>(prefix, prefix4, PUT, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods2['get']['query'] }) =>
                    `${prefix}${prefix4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                }
              },
              lessons_order: {
                /**
                 * コースに紐づくレッスンの並び順を変更する
                 * @returns 正常
                 */
                put: (option: { body: Methods3['put']['reqBody'], config?: T }) =>
                  fetch<Methods3['put']['resBody'], BasicHeaders, Methods3['put']['status']>(prefix, `${prefix3}${PATH1}`, PUT, option).json(),
                /**
                 * コースに紐づくレッスンの並び順を変更する
                 * @returns 正常
                 */
                $put: (option: { body: Methods3['put']['reqBody'], config?: T }) =>
                  fetch<Methods3['put']['resBody'], BasicHeaders, Methods3['put']['status']>(prefix, `${prefix3}${PATH1}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH1}`
              },
              /**
               * コースを論理削除して削除状態にする
               * @returns 正常
               */
              delete: (option?: { config?: T }) =>
                fetch<Methods1['delete']['resBody'], BasicHeaders, Methods1['delete']['status']>(prefix, prefix3, DELETE, option).json(),
              /**
               * コースを論理削除して削除状態にする
               * @returns 正常
               */
              $delete: (option?: { config?: T }) =>
                fetch<Methods1['delete']['resBody'], BasicHeaders, Methods1['delete']['status']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
              /**
               * コース情報を返却する コースに紐づくレッスン情報も含む
               * @returns 正常
               */
              get: (option?: { config?: T }) =>
                fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * コース情報を返却する コースに紐づくレッスン情報も含む
               * @returns 正常
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              /**
               * レッスン情報を作成するAPI
               * @returns 正常
               */
              post: (option: { body: Methods1['post']['reqBody'], config?: T }) =>
                fetch<Methods1['post']['resBody'], BasicHeaders, Methods1['post']['status']>(prefix, prefix3, POST, option).json(),
              /**
               * レッスン情報を作成するAPI
               * @returns 正常
               */
              $post: (option: { body: Methods1['post']['reqBody'], config?: T }) =>
                fetch<Methods1['post']['resBody'], BasicHeaders, Methods1['post']['status']>(prefix, prefix3, POST, option).json().then(r => r.body),
              /**
               * コース情報を更新する
               * @returns 正常
               */
              put: (option: { body: Methods1['put']['reqBody'], config?: T }) =>
                fetch<Methods1['put']['resBody'], BasicHeaders, Methods1['put']['status']>(prefix, prefix3, PUT, option).json(),
              /**
               * コース情報を更新する
               * @returns 正常
               */
              $put: (option: { body: Methods1['put']['reqBody'], config?: T }) =>
                fetch<Methods1['put']['resBody'], BasicHeaders, Methods1['put']['status']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`
            }
          },
          /**
           * スクール側のコース情報一覧を取得する
           * @returns 正常
           */
          get: (option: { query: Methods0['get']['query'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
          /**
           * スクール側のコース情報一覧を取得する
           * @returns 正常
           */
          $get: (option: { query: Methods0['get']['query'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
          /**
           * コースを作成する
           * @returns 正常
           */
          post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
            fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
          /**
           * コースを作成する
           * @returns 正常
           */
          $post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
            fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods0['get']['query'] }) =>
            `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        invite: {
          /**
           * メールアドレスと操作権限を指定して、スクールの運用をするメンバーを追加する
           * @returns 正常
           */
          post: (option: { body: Methods4['post']['reqBody'], config?: T }) =>
            fetch<Methods4['post']['resBody'], BasicHeaders, Methods4['post']['status']>(prefix, PATH2, POST, option).json(),
          /**
           * メールアドレスと操作権限を指定して、スクールの運用をするメンバーを追加する
           * @returns 正常
           */
          $post: (option: { body: Methods4['post']['reqBody'], config?: T }) =>
            fetch<Methods4['post']['resBody'], BasicHeaders, Methods4['post']['status']>(prefix, PATH2, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH2}`
        },
        members: {
          _school_member_id: (val3: number) => {
            const prefix3 = `${PATH3}/${val3}`

            return {
              /**
               * 指定したスクールの運用者を削除する
               * @returns 正常
               */
              delete: (option?: { config?: T }) =>
                fetch<Methods6['delete']['resBody'], BasicHeaders, Methods6['delete']['status']>(prefix, prefix3, DELETE, option).json(),
              /**
               * 指定したスクールの運用者を削除する
               * @returns 正常
               */
              $delete: (option?: { config?: T }) =>
                fetch<Methods6['delete']['resBody'], BasicHeaders, Methods6['delete']['status']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`
            }
          },
          /**
           * スクールの運用者情報を取得する
           * @returns 正常
           */
          get: (option: { query: Methods5['get']['query'], config?: T }) =>
            fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH3, GET, option).json(),
          /**
           * スクールの運用者情報を取得する
           * @returns 正常
           */
          $get: (option: { query: Methods5['get']['query'], config?: T }) =>
            fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods5['get']['query'] }) =>
            `${prefix}${PATH3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        sales: {
          courses: {
            /**
             * スクールのコース販売情報を返却する
             * @returns 正常
             */
            get: (option: { query: Methods8['get']['query'], config?: T }) =>
              fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH5, GET, option).json(),
            /**
             * スクールのコース販売情報を返却する
             * @returns 正常
             */
            $get: (option: { query: Methods8['get']['query'], config?: T }) =>
              fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH5, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get'; query: Methods8['get']['query'] }) =>
              `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          },
          /**
           * スクールの売上情報を返却する
           * @returns 正常
           */
          get: (option: { query: Methods7['get']['query'], config?: T }) =>
            fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH4, GET, option).json(),
          /**
           * スクールの売上情報を返却する
           * @returns 正常
           */
          $get: (option: { query: Methods7['get']['query'], config?: T }) =>
            fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods7['get']['query'] }) =>
            `${prefix}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        school: {
          /**
           * スクールの情報を取得する
           * @returns 正常
           */
          get: (option?: { config?: T }) =>
            fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH6, GET, option).json(),
          /**
           * スクールの情報を取得する
           * @returns 正常
           */
          $get: (option?: { config?: T }) =>
            fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
          /**
           * スクールの情報を更新する
           * @returns 正常
           */
          put: (option: { body: Methods9['put']['reqBody'], config?: T }) =>
            fetch<Methods9['put']['resBody'], BasicHeaders, Methods9['put']['status']>(prefix, PATH6, PUT, option).json(),
          /**
           * スクールの情報を更新する
           * @returns 正常
           */
          $put: (option: { body: Methods9['put']['reqBody'], config?: T }) =>
            fetch<Methods9['put']['resBody'], BasicHeaders, Methods9['put']['status']>(prefix, PATH6, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH6}`
        },
        upload: {
          exists: {
            /**
             * ファイルのアップロード完了を検証する。 正常にファイルがアップロードされていればURLが返却される。
             * @returns 正常
             */
            post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
              fetch<Methods10['post']['resBody'], BasicHeaders, Methods10['post']['status']>(prefix, PATH7, POST, option).json(),
            /**
             * ファイルのアップロード完了を検証する。 正常にファイルがアップロードされていればURLが返却される。
             * @returns 正常
             */
            $post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
              fetch<Methods10['post']['resBody'], BasicHeaders, Methods10['post']['status']>(prefix, PATH7, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH7}`
          },
          issue_url: {
            /**
             * 新規にファイルアップロードする際の署名付きURLを発行する。 フロントエンドはこのURLにファイルをPUTすることでS3へアップロードさせる。
             * @returns 正常
             */
            post: (option: { body: Methods11['post']['reqBody'], config?: T }) =>
              fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, PATH8, POST, option).json(),
            /**
             * 新規にファイルアップロードする際の署名付きURLを発行する。 フロントエンドはこのURLにファイルをPUTすることでS3へアップロードさせる。
             * @returns 正常
             */
            $post: (option: { body: Methods11['post']['reqBody'], config?: T }) =>
              fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, PATH8, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH8}`
          }
        }
      },
      users: {
        auth: {
          password: {
            /**
             * 一般ユーザーがパスワード忘れたときの再発行申請API このAPI叩くとメールが飛び、パスワード変更画面のURLが記載されたものが送られる。
             * @returns 正常
             */
            post: (option: { body: Methods13['post']['reqBody'], config?: T }) =>
              fetch<Methods13['post']['resBody'], BasicHeaders, Methods13['post']['status']>(prefix, PATH10, POST, option).json(),
            /**
             * 一般ユーザーがパスワード忘れたときの再発行申請API このAPI叩くとメールが飛び、パスワード変更画面のURLが記載されたものが送られる。
             * @returns 正常
             */
            $post: (option: { body: Methods13['post']['reqBody'], config?: T }) =>
              fetch<Methods13['post']['resBody'], BasicHeaders, Methods13['post']['status']>(prefix, PATH10, POST, option).json().then(r => r.body),
            /**
             * 新しいパスワードを設定するAPI。 パスワード再発行申請API(post)を叩くと再発行申請時に指定したメールアドレスに再発行APIのURLが送信される。そのAPIを叩くと内部でreset-tokenの検証が行われ、フロント側の再設定画面にリダイレクトするタイミングで認証情報がリクエストパラメータに積まれる、このAPIはその際に渡された認証情報を用いて、変更パスワード、パスワード確認の値を送信する。
             * @returns 正常
             */
            put: (option: { body: Methods13['put']['reqBody'], config?: T }) =>
              fetch<Methods13['put']['resBody'], BasicHeaders, Methods13['put']['status']>(prefix, PATH10, PUT, option).json(),
            /**
             * 新しいパスワードを設定するAPI。 パスワード再発行申請API(post)を叩くと再発行申請時に指定したメールアドレスに再発行APIのURLが送信される。そのAPIを叩くと内部でreset-tokenの検証が行われ、フロント側の再設定画面にリダイレクトするタイミングで認証情報がリクエストパラメータに積まれる、このAPIはその際に渡された認証情報を用いて、変更パスワード、パスワード確認の値を送信する。
             * @returns 正常
             */
            $put: (option: { body: Methods13['put']['reqBody'], config?: T }) =>
              fetch<Methods13['put']['resBody'], BasicHeaders, Methods13['put']['status']>(prefix, PATH10, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH10}`
          },
          sign_in: {
            /**
             * 一般ユーザー向けのログイン処理を実施する
             * @returns 正常
             */
            post: (option: { body: Methods14['post']['reqBody'], config?: T }) =>
              fetch<Methods14['post']['resBody'], Methods14['post']['resHeaders'], Methods14['post']['status']>(prefix, PATH11, POST, option).json(),
            /**
             * 一般ユーザー向けのログイン処理を実施する
             * @returns 正常
             */
            $post: (option: { body: Methods14['post']['reqBody'], config?: T }) =>
              fetch<Methods14['post']['resBody'], Methods14['post']['resHeaders'], Methods14['post']['status']>(prefix, PATH11, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH11}`
          },
          sign_up: {
            /**
             * ユーザー登録開始時にメールを飛ばし、本登録用URLを送信する
             * @returns 正常
             */
            post: (option: { body: Methods15['post']['reqBody'], config?: T }) =>
              fetch<Methods15['post']['resBody'], BasicHeaders, Methods15['post']['status']>(prefix, PATH12, POST, option).json(),
            /**
             * ユーザー登録開始時にメールを飛ばし、本登録用URLを送信する
             * @returns 正常
             */
            $post: (option: { body: Methods15['post']['reqBody'], config?: T }) =>
              fetch<Methods15['post']['resBody'], BasicHeaders, Methods15['post']['status']>(prefix, PATH12, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH12}`
          },
          /**
           * ユーザーの情報と登録用トークンを受け取って新規にユーザーを作成する
           * @returns 正常
           */
          post: (option: { body: Methods12['post']['reqBody'], config?: T }) =>
            fetch<Methods12['post']['resBody'], Methods12['post']['resHeaders'], Methods12['post']['status']>(prefix, PATH9, POST, option).json(),
          /**
           * ユーザーの情報と登録用トークンを受け取って新規にユーザーを作成する
           * @returns 正常
           */
          $post: (option: { body: Methods12['post']['reqBody'], config?: T }) =>
            fetch<Methods12['post']['resBody'], Methods12['post']['resHeaders'], Methods12['post']['status']>(prefix, PATH9, POST, option).json().then(r => r.body),
          /**
           * ユーザー名とパスワードを変更する このAPIは、渡す情報によって内部で処理が分岐されます。 パスワード情報を渡している場合、パスワードのみの情報が更新されます。その際パスワード更新トークンが必要になります。 その他、ユーザープロフィール情報を渡している場合、パスワード、メールアドレスを除いた情報だけ更新されます。こちらは更新トークン不要です。
           * @returns 正常
           */
          put: (option: { body: Methods12['put']['reqBody'], config?: T }) =>
            fetch<Methods12['put']['resBody'], BasicHeaders, Methods12['put']['status']>(prefix, PATH9, PUT, option).json(),
          /**
           * ユーザー名とパスワードを変更する このAPIは、渡す情報によって内部で処理が分岐されます。 パスワード情報を渡している場合、パスワードのみの情報が更新されます。その際パスワード更新トークンが必要になります。 その他、ユーザープロフィール情報を渡している場合、パスワード、メールアドレスを除いた情報だけ更新されます。こちらは更新トークン不要です。
           * @returns 正常
           */
          $put: (option: { body: Methods12['put']['reqBody'], config?: T }) =>
            fetch<Methods12['put']['resBody'], BasicHeaders, Methods12['put']['status']>(prefix, PATH9, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH9}`
        },
        courses: {
          _course_key: (val3: string) => {
            const prefix3 = `${PATH13}/${val3}`

            return {
              _lesson_key: (val4: string) => {
                const prefix4 = `${prefix3}/${val4}`

                return {
                  /**
                   * ユーザー側レッスン情報を取得する
                   * @returns 正常
                   */
                  get: (option?: { config?: T }) =>
                    fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * ユーザー側レッスン情報を取得する
                   * @returns 正常
                   */
                  $get: (option?: { config?: T }) =>
                    fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              subscribe: {
                /**
                 * コースを購読する。 有料のコースの場合登録しているクレジットカードで決済する。 API実行時、カード情報が登録されていない場合、カード登録を促すレスポンスを返却する。
                 * @returns 正常
                 */
                post: (option?: { config?: T }) =>
                  fetch<Methods19['post']['resBody'], BasicHeaders, Methods19['post']['status']>(prefix, `${prefix3}${PATH14}`, POST, option).json(),
                /**
                 * コースを購読する。 有料のコースの場合登録しているクレジットカードで決済する。 API実行時、カード情報が登録されていない場合、カード登録を促すレスポンスを返却する。
                 * @returns 正常
                 */
                $post: (option?: { config?: T }) =>
                  fetch<Methods19['post']['resBody'], BasicHeaders, Methods19['post']['status']>(prefix, `${prefix3}${PATH14}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH14}`
              },
              verify: {
                /**
                 * 購読対象コースの最新の情報を返却する 決済前にコースが購読できるかをチェックする
                 * @returns 正常
                 */
                get: (option?: { config?: T }) =>
                  fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, `${prefix3}${PATH15}`, GET, option).json(),
                /**
                 * 購読対象コースの最新の情報を返却する 決済前にコースが購読できるかをチェックする
                 * @returns 正常
                 */
                $get: (option?: { config?: T }) =>
                  fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, `${prefix3}${PATH15}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH15}`
              },
              /**
               * コース情報を返却する コースに紐づくレッスン情報も含む
               * @returns 正常
               */
              get: (option?: { config?: T }) =>
                fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * コース情報を返却する コースに紐づくレッスン情報も含む
               * @returns 正常
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`
            }
          },
          /**
           * コース情報一覧を取得する
           * @returns 正常
           */
          get: (option: { query: Methods16['get']['query'], config?: T }) =>
            fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, PATH13, GET, option).json(),
          /**
           * コース情報一覧を取得する
           * @returns 正常
           */
          $get: (option: { query: Methods16['get']['query'], config?: T }) =>
            fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, PATH13, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods16['get']['query'] }) =>
            `${prefix}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        payment: {
          histories: {
            /**
             * ユーザーが決済した履歴を直近の順に並べたものを返却する
             * @returns 正常
             */
            get: (option: { query: Methods21['get']['query'], config?: T }) =>
              fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, PATH16, GET, option).json(),
            /**
             * ユーザーが決済した履歴を直近の順に並べたものを返却する
             * @returns 正常
             */
            $get: (option: { query: Methods21['get']['query'], config?: T }) =>
              fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, PATH16, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get'; query: Methods21['get']['query'] }) =>
              `${prefix}${PATH16}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          },
          methods: {
            /**
             * クレジットカード情報をシステムから削除する
             * @returns 正常
             */
            delete: (option?: { config?: T }) =>
              fetch<Methods22['delete']['resBody'], BasicHeaders, Methods22['delete']['status']>(prefix, PATH17, DELETE, option).json(),
            /**
             * クレジットカード情報をシステムから削除する
             * @returns 正常
             */
            $delete: (option?: { config?: T }) =>
              fetch<Methods22['delete']['resBody'], BasicHeaders, Methods22['delete']['status']>(prefix, PATH17, DELETE, option).json().then(r => r.body),
            /**
             * 登録済みの有効なカード情報を取得する
             * @returns 正常
             */
            get: (option?: { config?: T }) =>
              fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, PATH17, GET, option).json(),
            /**
             * 登録済みの有効なカード情報を取得する
             * @returns 正常
             */
            $get: (option?: { config?: T }) =>
              fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, PATH17, GET, option).json().then(r => r.body),
            /**
             * クレジットカード情報をシステムに登録する 購入時、まだカードが登録されていない場合はこのAPIを呼び出す
             * @returns 正常
             */
            post: (option: { body: Methods22['post']['reqBody'], config?: T }) =>
              fetch<Methods22['post']['resBody'], BasicHeaders, Methods22['post']['status']>(prefix, PATH17, POST, option).json(),
            /**
             * クレジットカード情報をシステムに登録する 購入時、まだカードが登録されていない場合はこのAPIを呼び出す
             * @returns 正常
             */
            $post: (option: { body: Methods22['post']['reqBody'], config?: T }) =>
              fetch<Methods22['post']['resBody'], BasicHeaders, Methods22['post']['status']>(prefix, PATH17, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH17}`
          }
        },
        school: {
          /**
           * スクールの情報を取得する
           * @returns 正常
           */
          get: (option?: { config?: T }) =>
            fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, PATH18, GET, option).json(),
          /**
           * スクールの情報を取得する
           * @returns 正常
           */
          $get: (option?: { config?: T }) =>
            fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, PATH18, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH18}`
        },
        settings: {
          apply_change_email: {
            /**
             * 一般ユーザー向けのメールアドレス変更申請を受け付ける
             * @returns 正常
             */
            post: (option: { body: Methods24['post']['reqBody'], config?: T }) =>
              fetch<Methods24['post']['resBody'], BasicHeaders, Methods24['post']['status']>(prefix, PATH19, POST, option).json(),
            /**
             * 一般ユーザー向けのメールアドレス変更申請を受け付ける
             * @returns 正常
             */
            $post: (option: { body: Methods24['post']['reqBody'], config?: T }) =>
              fetch<Methods24['post']['resBody'], BasicHeaders, Methods24['post']['status']>(prefix, PATH19, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH19}`
          }
        }
      }
    }
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
