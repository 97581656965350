import { useEffect, useContext, useState, useCallback } from 'react';
import { SchoolContext } from './schoolContext';
import { UseSchoolContextState } from './schoolInterface';
import { UseApiContextState } from '../api';

export const useSchool = (apiCtx: UseApiContextState): UseSchoolContextState => {
  const [state, setState] = useState<Omit<UseSchoolContextState, 'getSchoolInfo'>>({});

  useEffect(() => {
    if (!state.school || !state.courses) {
      Promise.all([getSchoolInfo()]);
    }
  }, []);

  const getSchoolInfo = useCallback(async () => {
    const res = await apiCtx.api.users.school.$get();
    setState(res.data);
  }, []);

  return {
    ...state,
    getSchoolInfo
  };
};

export const useSchoolContext = () => useContext(SchoolContext);
