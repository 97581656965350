/* eslint-disable */
// prettier-ignore
import { Query as Query0 } from '../pages/account/payment/histories'
// prettier-ignore
import { Query as Query1 } from '../pages/admin/sign_in'
// prettier-ignore
import { Query as Query2 } from '../pages/courses/[courseKey]/thank_you_subscribed'
// prettier-ignore
import { Query as Query3 } from '../pages/sign_in'
// prettier-ignore
import { Query as Query4 } from '../pages/sign_up'

// prettier-ignore
export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  account: {
    password_setting: {
      $url: (url?: { hash?: string }) => ({ pathname: '/account/password_setting' as const, hash: url?.hash })
    },
    payment: {
      histories: {
        $url: (url: { query: Query0, hash?: string }) => ({ pathname: '/account/payment/histories' as const, query: url.query, hash: url.hash })
      },
      methods: {
        $url: (url?: { hash?: string }) => ({ pathname: '/account/payment/methods' as const, hash: url?.hash })
      }
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/account' as const, hash: url?.hash })
  },
  admin: {
    courses: {
      _courseKey: (courseKey: string | number) => ({
        _lessonKey: (lessonKey: string | number) => ({
          edit: {
            $url: (url?: { hash?: string }) => ({ pathname: '/admin/courses/[courseKey]/[lessonKey]/edit' as const, query: { courseKey, lessonKey }, hash: url?.hash })
          }
        }),
        edit: {
          $url: (url?: { hash?: string }) => ({ pathname: '/admin/courses/[courseKey]/edit' as const, query: { courseKey }, hash: url?.hash })
        },
        lessons: {
          $url: (url?: { hash?: string }) => ({ pathname: '/admin/courses/[courseKey]/lessons' as const, query: { courseKey }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/courses' as const, hash: url?.hash })
    },
    members: {
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/members' as const, hash: url?.hash })
    },
    school: {
      edit: {
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/school/edit' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/school' as const, hash: url?.hash })
    },
    sign_in: {
      $url: (url: { query: Query1, hash?: string }) => ({ pathname: '/admin/sign_in' as const, query: url.query, hash: url.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/admin' as const, hash: url?.hash })
  },
  change_email: {
    confirmed: {
      $url: (url?: { hash?: string }) => ({ pathname: '/change_email/confirmed' as const, hash: url?.hash })
    },
    edit: {
      $url: (url?: { hash?: string }) => ({ pathname: '/change_email/edit' as const, hash: url?.hash })
    },
    request: {
      $url: (url?: { hash?: string }) => ({ pathname: '/change_email/request' as const, hash: url?.hash })
    }
  },
  change_password: {
    confirmed: {
      $url: (url?: { hash?: string }) => ({ pathname: '/change_password/confirmed' as const, hash: url?.hash })
    },
    edit: {
      $url: (url?: { hash?: string }) => ({ pathname: '/change_password/edit' as const, hash: url?.hash })
    }
  },
  courses: {
    _courseKey: (courseKey: string | number) => ({
      _lessonKey: (lessonKey: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/courses/[courseKey]/[lessonKey]' as const, query: { courseKey, lessonKey }, hash: url?.hash })
      }),
      subscribe: {
        $url: (url?: { hash?: string }) => ({ pathname: '/courses/[courseKey]/subscribe' as const, query: { courseKey }, hash: url?.hash })
      },
      thank_you_subscribed: {
        $url: (url: { query: Query2, hash?: string }) => ({ pathname: '/courses/[courseKey]/thank_you_subscribed' as const, query: { courseKey, ...url.query }, hash: url.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/courses/[courseKey]' as const, query: { courseKey }, hash: url?.hash })
    })
  },
  forgot: {
    $url: (url?: { hash?: string }) => ({ pathname: '/forgot' as const, hash: url?.hash })
  },
  news: {
    _newsId: (newsId: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/news/[newsId]' as const, query: { newsId }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/news' as const, hash: url?.hash })
  },
  privacy: {
    $url: (url?: { hash?: string }) => ({ pathname: '/privacy' as const, hash: url?.hash })
  },
  register: {
    confirmed: {
      $url: (url?: { hash?: string }) => ({ pathname: '/register/confirmed' as const, hash: url?.hash })
    },
    edit: {
      $url: (url?: { hash?: string }) => ({ pathname: '/register/edit' as const, hash: url?.hash })
    },
    request: {
      $url: (url?: { hash?: string }) => ({ pathname: '/register/request' as const, hash: url?.hash })
    }
  },
  reset_password: {
    confirmed: {
      $url: (url?: { hash?: string }) => ({ pathname: '/reset_password/confirmed' as const, hash: url?.hash })
    },
    edit: {
      $url: (url?: { hash?: string }) => ({ pathname: '/reset_password/edit' as const, hash: url?.hash })
    },
    request_confirmed: {
      $url: (url?: { hash?: string }) => ({ pathname: '/reset_password/request_confirmed' as const, hash: url?.hash })
    }
  },
  sign_in: {
    $url: (url: { query: Query3, hash?: string }) => ({ pathname: '/sign_in' as const, query: url.query, hash: url.hash })
  },
  sign_up: {
    $url: (url: { query: Query4, hash?: string }) => ({ pathname: '/sign_up' as const, query: url.query, hash: url.hash })
  },
  terms: {
    $url: (url?: { hash?: string }) => ({ pathname: '/terms' as const, hash: url?.hash })
  },
  tokutei: {
    $url: (url?: { hash?: string }) => ({ pathname: '/tokutei' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

// prettier-ignore
export type PagesPath = typeof pagesPath
