/* eslint-disable @typescript-eslint/no-explicit-any */
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (!GA_TRACKING_ID) return;
  (window as any).gtag('config', GA_TRACKING_ID, {
    page_path: url
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action: string, params?: any) => {
  if (!GA_TRACKING_ID) return;
  (window as any).gtag('event', action, params || {});
};

export const setUserProperties = ({ schoolMember }: { schoolMember: boolean }) => {
  if (!GA_TRACKING_ID) return;
  (window as any).gtag('set', 'user_properties', {
    school_member: schoolMember ? 'true' : 'false'
  });
};
