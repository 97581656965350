// リクエストパラメータからいい感じに値を引っ張り出すやつ
export const requestParams = (path: string) => {
  const params = {} as {
    [key: string]: string;
  };
  path.split(/&|\?/).forEach((set) => {
    const [key, val] = decodeURIComponent(set).split('=');
    params[key] = val;
  });
  return params;
};
