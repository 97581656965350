import { ErrorMessage } from '@/hooks/error';
import { Icon, IconButton, Snackbar } from '@material-ui/core';
import styles from './BaseSnackbar.scss';

export type SnackbarMessage = ErrorMessage;

type ElementProps = JSX.IntrinsicElements['div'];
type BaseSnackbar = {
  open: boolean;
  messageInfo?: SnackbarMessage;
  handleClose: (event: React.SyntheticEvent | MouseEvent, reason?: string) => void;
  handleExited: () => void;
};
type Props = ElementProps & BaseSnackbar;

/** Snackbar */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BaseSnackbar: React.FC<Props> = ({ children, ...props }) => {
  const { open, messageInfo, handleClose, handleExited, ...restProps } = props;

  return (
    <div {...restProps}>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <IconButton aria-label="close" color="inherit" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        }
      />
      <style jsx>{styles}</style>
    </div>
  );
};
