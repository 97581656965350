import { useErrorContext } from '@/hooks/error';
import { useEffect, useState } from 'react';
import { BaseSnackbar, SnackbarMessage } from '@/components/molecules/snackbar/BaseSnackbar';
import styles from './ErrorConductor.scss';
import { Maintenance } from './Mainetnance';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import dayjs from 'dayjs';
import { useRouter } from 'next/dist/client/router';
import { pagesPath } from '@/lib/$path';

/** ErrorConductor */
export const ErrorConductor: React.FC = ({ children }) => {
  const { errors, setErrors, maintenance, notFoundPage, setNotFoundPage } = useErrorContext();
  const { flashMessage, setFlashMessage } = useFlashMessage();

  // const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined);
  const router = useRouter();

  const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  useEffect(() => {
    const hasMessage = errors.length || flashMessage.length;
    if (hasMessage && !messageInfo) {
      if (errors.length) {
        setMessageInfo({ ...errors[0] });
        setErrors((prev) => prev.slice(1));
      } else if (flashMessage.length) {
        setMessageInfo({ message: flashMessage, key: dayjs().unix() });
        setFlashMessage('');
      }
      setOpen(true);
    } else if (hasMessage && messageInfo && open) {
      setOpen(false);
    }
  }, [errors, flashMessage, messageInfo, open]);

  if (maintenance) return <Maintenance></Maintenance>;
  if (notFoundPage) {
    setNotFoundPage(false);
    router.push(pagesPath.$404.$url());
  }

  return (
    <div>
      {children}
      <BaseSnackbar open={open} handleClose={handleClose} handleExited={handleExited} messageInfo={messageInfo} />
      <style jsx>{styles}</style>
    </div>
  );
};
