import { useReducer, useEffect, useContext, useMemo, useCallback, useState } from 'react';
import { AuthContext } from './authContext';
import { AuthState, AuthStorage, UserState } from './authInterface';
import { authCookies } from './authStorage';
import { ErrorEventType, UseApiContextState } from '../api';
import { event, setUserProperties } from '@/lib/gtag';
import * as Sentry from '@sentry/nextjs';

/**
 * authStateの初期値
 */
const initialState: AuthStorage = {
  uid: '',
  accessToken: '',
  currentUser: {
    email: '',
    name: '',
    nickname: '',
    school_member: null
  }
};

function reducer(state: AuthStorage, action: AuthStorage) {
  const result = { ...state, ...action };
  authCookies.set(result);
  return result;
}

export const useAuth = (apiCtx: UseApiContextState) => {
  const [state, dispatch] = useReducer(reducer, authCookies.get() || initialState);

  const checkAuthState = useCallback(() => {
    return [state.accessToken, state.uid, state.currentUser].every(Boolean);
  }, [state.currentUser]);

  const currentUser = useMemo<UserState>(() => state.currentUser, [state.currentUser]);

  const isLoggedIn = useMemo(() => checkAuthState(), [currentUser]);

  const isActivatedSchoolMember = useMemo(() => !!currentUser.school_member?.activated, [currentUser]);

  const isLoggedInSchoolOperator = useMemo(
    () => isLoggedIn && isActivatedSchoolMember,
    [isLoggedIn, isActivatedSchoolMember]
  );

  const [isPurposeSignOut, setIsPurposeSignOut] = useState(false);

  // set sentry user id
  useEffect(() => {
    Sentry.setUser({ id: state?.uid });
  }, [state]);

  // set user properties for gtag
  useEffect(() => {
    setUserProperties({
      schoolMember: !!currentUser.school_member?.activated
    });
  }, [currentUser]);

  useEffect(() => {
    const handleUnauthorized = () => {
      dispatch(initialState);
    };
    apiCtx.errorEventBus.on(ErrorEventType.unauthorized, handleUnauthorized);
    return () => {
      apiCtx.errorEventBus.off(ErrorEventType.unauthorized, handleUnauthorized);
    };
  }, [dispatch, initialState]);

  return {
    /**
     * ログイン判定
     */
    isLoggedIn,

    /**
     * ログインしているユーザー情報
     */
    currentUser,

    /**
     * ログインしているユーザーが有効なスクール運用者か判定
     */
    isLoggedInSchoolOperator,

    /**
     * 意図的にサインアウトしたかどうか
     */
    isPurposeSignOut,

    /**
     * 意図的にサインアウト状態の更新
     */
    setIsPurposeSignOut,

    /**
     * ユーザー登録
     */
    signUp: async (params: Parameters<typeof apiCtx.api.users.auth.sign_up.post>[0]['body']) => {
      await apiCtx.api.users.auth.sign_up.post({ body: params });
      event('sign_up');
    },

    /**
     * ユーザー本登録
     */
    signUpConfirm: async (params: Omit<Parameters<typeof apiCtx.api.users.auth.post>[0]['body'], 'nickname'>) => {
      // 1stでは、 nickname を利用する場面がない為 null で対応を実施する
      // https://github.com/chot-Inc/teachyou/issues/238#issuecomment-878058345
      const requestBody = {
        ...params,
        nickname: null
      };
      const { headers, body } = await apiCtx.api.users.auth.post({ body: requestBody });

      // 認証情報をセット
      dispatch({
        accessToken: headers['access-token'],
        uid: headers.uid,
        currentUser: body.data.user
      });

      event('sign-up-confirm');
    },

    /**
     * ログイン
     */
    signIn: async (params: Parameters<typeof apiCtx.api.users.auth.sign_in.post>[0]['body']) => {
      const { headers, body } = await apiCtx.api.users.auth.sign_in.post({ body: params });

      // 認証情報をセット
      dispatch({
        accessToken: headers['access-token'],
        uid: headers.uid,
        currentUser: body.data.user
      });

      // tracking
      event('login');
    },

    /**
     * ログアウト
     */
    signOut: async () => {
      new Promise((resolve) => {
        // 認証情報をリセット
        dispatch(initialState);
        setIsPurposeSignOut(true);
        resolve(true);
      });
    },

    forgot: async (params: Parameters<typeof apiCtx.api.users.auth.password.post>[0]['body']) => {
      await apiCtx.api.users.auth.password.post({ body: params });
    },

    reset_password: async (params: Parameters<typeof apiCtx.api.users.auth.password.put>[0]['body']) => {
      await apiCtx.api.users.auth.password.put({ body: params });
      dispatch(initialState);
    },

    /** パスワードやメールアドレスを変更する */
    // TODO: openapi2aspidaバージョンアップまでのあいだ暫定的に params を any とする
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change_user: async (params: any, authState: AuthState | null) => {
      const isNeedAuthParams = authState !== null;
      const beforeAuthState = { ...state };

      // 更新に認証情報を後乗せする場合は認証情報をCookieに詰めて通せるようにする
      if (isNeedAuthParams && authState) {
        dispatch({
          ...authState,
          currentUser: { ...initialState.currentUser }
        });
      }

      try {
        await apiCtx.api.users.auth.put({ body: params as Parameters<typeof apiCtx.api.users.auth.put>[0]['body'] });
        dispatch(initialState);
      } catch (error) {
        // 認証情報を変更していた場合は、失敗したときもとの認証情報を再設定する
        if (isNeedAuthParams) {
          dispatch(beforeAuthState);
        }
        throw error;
      }
    },

    /** ユーザープロフィールを更新する */
    updateUserAttributes: async ({ name }: { name: string }) => {
      const body = {
        name: name
      } as Parameters<typeof apiCtx.api.users.auth.put>[0]['body'];
      const res = await apiCtx.api.users.auth.$put({ body });
      const authState = authCookies.get();
      if (authState) {
        dispatch({
          ...authState,
          currentUser: res.data.user
        });
      }
    }
  };
};

export const useAuthContext = () => useContext(AuthContext);
