import { dayjs } from '@/lib/dayjs';
import { useContext, useState } from 'react';
import { ErrorContext } from './errorContext';
import { ErrorMessage, UseErrorContextState } from './errorInterface';

export const useError = (): UseErrorContextState => {
  const [errors, setErrors] = useState<ErrorMessage[]>([]);

  const pushError = (message: string) => {
    setErrors([...errors, { message, key: dayjs().unix() }]);
  };

  const [maintenance, setMaintenance] = useState<boolean>(false);
  const [notFoundPage, setNotFoundPage] = useState<boolean>(false);

  return {
    errors,
    setErrors,
    pushError,
    maintenance,
    setMaintenance,
    notFoundPage,
    setNotFoundPage
  };
};

export const useErrorContext = () => useContext(ErrorContext);
