import { cookies } from '@/lib/cookies';
import { AuthStorage } from './authInterface';

const COOKIE_KEY = 'CURRENT_USER';

export const authCookies = {
  get: (): AuthStorage | null => {
    const result = cookies.get(COOKIE_KEY);
    return result ? JSON.parse(result) : null;
  },
  set: (value: AuthStorage): void => {
    cookies.set(COOKIE_KEY, value);
  }
};
